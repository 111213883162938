<template>
  <div v-if="loading" class="page-container">
    <div class="container-title-block">
      <div class="title-block-text">Теги</div>
      <div class="title-block-search">
        <SearchInput class="ml-4" v-model="search" label="Поиск тегов" />
      </div>
    </div>
    <div class="container-subtitle-block">
      <div>
        <v-btn
          @click="openDialog({ tagType: 'value' }, true)"
          depressed
          class="button-white-blue mr-4"
        >
          <v-icon small>mdi-format-list-bulleted</v-icon
          ><span>Добавить тег по значению</span>
        </v-btn>
        <v-btn
          @click="openDialog({ tagType: 'date' }, true)"
          depressed
          class="button-white-blue"
        >
          <v-icon small>mdi-calendar-range</v-icon
          ><span>Добавить тег по дате</span>
        </v-btn>
      </div>
      <div class="d-flex align-center">
        <div class="subtitle-block-count">
          Всего тегов: {{ itemsAll.length }}
        </div>
      </div>
    </div>
    <div class="container-table">
      <Table
        :items="searchingItems"
        :headers="headers"
        headerFixed
        @click:row="openDialog"
      >
        <template v-slot:[`item.tagType`]="{ item }">
          <div v-if="item.tagType == 'date'">По дате</div>
          <div v-else-if="item.tagType == 'value'">По значению</div>
        </template>
        <template v-slot:[`item.values`]="{ item }">
          <div v-if="item.tagType == 'value'">
            {{ item.values.length }}
          </div>
          <div v-else></div>
        </template>
      </Table>
    </div>

    <Dialog
      v-model="dialogTag"
      :titleText="tagDialogObj.titleDialog"
      :refForm="'formTag'"
      @click:success="saveTag"
      @click:delete="checkDeleteInfo(selectedItem.id, 'tag', selectedItem)"
      :buttonsFooter="{
        success: {
          title: tagDialogObj.textButton,
          loading: true,
        },
        delete: {
          title: selectedItem.id ? 'Удалить тег' : null,
        },
      }"
    >
      <template v-slot:blockMain>
        <div>
          <TextInput v-model="selectedItem.name" label="Название тега" valid />
        </div>
        <div class="dialog-items" v-if="selectedItem.tagType == 'value'">
          <div class="dialog-items-subheader">
            <div class="subheader-text">Значения</div>
            <v-btn @click="addTagValue" depressed class="button-white-blue">
              Добавить значение
            </v-btn>
          </div>
          <div class="dialog-items-values" ref="valuesBlock">
            <div
              class="values-block"
              v-for="(tagValue, index) in selectedItem.values"
              :key="index"
            >
              <TextInput v-model="tagValue.value" label="Значение" />
              <div @click="deleteValueTag(tagValue)" class="values-block-icon">
                <v-icon>mdi-close</v-icon>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Dialog>
    <Dialog v-model="dialogConfirm" :titleText="dialogConfirmObj.titleText">
      <template v-slot:blockMain>
        <div class="dialog-text">
          {{ dialogConfirmObj.mainText }}
        </div>
        <div
          class="dialog-table-container"
          v-if="dialogConfirmObj.groups.length > 0"
        >
          <Table
            :items="dialogConfirmObj.groups"
            :headers="headersConfirm"
          ></Table>
        </div>
      </template>
      <template v-slot:blockFooter>
        <v-btn
          depressed
          @click="dialogConfirm = false"
          class="button-white-blue"
        >
          Отменить
        </v-btn>
        <v-btn depressed @click="successDeleteTagValue" class="button-red">
          Удалить
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>
<script>
import SearchInput from "@/components/main/inputs/SearchInput";
import TextInput from "@/components/main/inputs/TextInput";

export default {
  components: { SearchInput, TextInput },
  data: () => ({
    loading: false,
    search: "",
    items: [],
    itemsAll: [],
    headers: [
      {
        text: "Тип",
        align: "flex-left",
        sortable: false,
        value: "tagType",
      },
      {
        text: "Название",
        align: "flex-left",
        sortable: false,
        value: "name",
      },
      {
        text: "Количество значений",
        align: "flex-end",
        sortable: false,
        value: "values",
      },
    ],
    headersConfirm: [
      {
        text: "",
        align: "flex-left",
        sortable: false,
        value: "title",
      },
    ],
    dialogTag: false,
    dialogConfirm: false,
    tagDialogObj: {
      titleDialog: "",
      textButton: "Добавить тег",
    },
    dialogConfirmObj: {
      titleText: "",
      mainText: "",
      groups: [],
      tag: {},
    },
    selectedItem: {
      values: [],
    },
  }),
  computed: {
    searchingItems() {
      return this.itemsAll.filter((i) =>
        i.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    getItems() {
      this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/tag/list", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.itemsAll = res.data;
          // this.fiftyItems();
          this.loading = true;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    // fiftyItems() {
    //   if (this.itemsAll.length < 50) {
    //     this.items = this.itemsAll;
    //   } else {
    //     for (let i = 0; i < 50; i++) {
    //       const element = this.itemsAll[i];
    //       this.items.push(element);
    //     }
    //   }
    // },
    // scrollMethod(e) {
    //   const scrollTop = e.target.scrollTop;
    //   const scrollHeight = e.target.scrollHeight - e.target.clientHeight;
    //   if (scrollTop >= scrollHeight - 1000) {
    //     this.sliceConcat();
    //   }
    // },
    // sliceConcat() {
    //   let arrSliced = this.itemsAll.slice(
    //     this.items.length,
    //     this.items.length + 50
    //   );
    //   this.items = this.items.concat(arrSliced);
    // },
    openDialog(item, create) {
      if (create) {
        if (item.tagType == "value") {
          this.tagDialogObj = {
            titleDialog: "Добавление тега по значению",
            textButton: "Добавить тег",
          };
        } else {
          this.tagDialogObj = {
            titleDialog: "Добавление тега по дате",
            textButton: "Добавить тег",
          };
        }
      } else {
        if (item.tagType == "value") {
          this.tagDialogObj = {
            titleDialog: "Редактирование тега по значению",
            textButton: "Сохранить",
          };
        } else {
          this.tagDialogObj = {
            titleDialog: "Редактирование тега по дате",
            textButton: "Сохранить",
          };
        }
      }
      if (!item.id) {
        item.values = [];
        item.name = "";
      }
      this.selectedItem = JSON.parse(JSON.stringify(item));
      if (this.selectedItem.values && this.selectedItem.values.length > 0) {
        this.selectedItem.values.map((i) => {
          i.idNew = (~~(Math.random() * 1e8)).toString(16);
          return i;
        });
      } else {
        this.selectedItem.values.push({
          idNew: (~~(Math.random() * 1e8)).toString(16),
          value: "",
        });
      }
      this.dialogTag = true;
    },
    deleteValueTag(tag) {
      if (!tag.id) {
        this.filterTagValues(tag);
      } else {
        this.checkDeleteInfo(tag.id, "value", tag);
      }
    },
    filterTagValues(tag) {
      this.selectedItem.values = this.selectedItem.values.filter(
        (i) => i.idNew != tag.idNew
      );
    },

    checkDeleteInfo(id, type, tag) {
      this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/tag/delete/info", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: id,
            type: type,
          },
        })
        .then((res) => {
          if (type == "value") {
            if (res.data.groups.length > 0 || res.data.countUsers > 0) {
              this.dialogConfirmObj = {
                titleText: "Удаление значение тега",
                mainText: `Вы уверены что хотите удалить значение тега? Оно будет удалено у ${
                  res.data.countUsers
                } ${this.declinationOfWords(res.data.countUsers, [
                  "сотрудникa",
                  "сотрудников",
                  "сотрудников",
                ])}. ${
                  res.data.groups.length > 0
                    ? "Следующие smart-группы будут переведены в обычные:"
                    : ""
                }`,
                groups: res.data.groups,
                tag: tag,
              };
              this.dialogConfirm = true;
            } else {
              this.filterTagValues(tag);
            }
          } else {
            if (res.data.groups.length > 0 || res.data.countUsers > 0) {
              this.dialogConfirmObj = {
                titleText: "Удаление тега",
                mainText: `Вы уверены что хотите удалить тег? Он будет удален у ${
                  res.data.countUsers
                } ${this.declinationOfWords(res.data.countUsers, [
                  "сотрудникa",
                  "сотрудников",
                  "сотрудников",
                ])}. ${
                  res.data.groups.length > 0
                    ? "Следующие smart-группы будут переведены в обычные:"
                    : ""
                }`,
                groups: res.data.groups,
                tag: tag,
              };
            } else {
              this.dialogConfirmObj = {
                titleText: "Удаление тега",
                mainText: `Вы уверены что хотите удалить тег?`,
                groups: 0,
                tag: tag,
              };
            }
            this.dialogConfirm = true;
          }
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    successDeleteTagValue() {
      if (this.dialogConfirmObj.tag.tagType) {
        this.$axios
          .delete(this.$store.getters.apiAdminPanelV4 + "/tag", {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
            params: { tagId: this.dialogConfirmObj.tag.id },
          })
          .then(() => {
            this.dialogConfirm = false;
            this.dialogTag = false;
            this.getItems();
          })
          .catch((err) => {
            this.$store.commit("errorSet", true);
            this.$store.commit("errorTextSet", err);
          });
      } else {
        this.filterTagValues(this.dialogConfirmObj.tag);
        this.$nextTick(() => {
          this.dialogConfirm = false;
        });
      }
    },
    addTagValue() {
      this.selectedItem.values.push({
        idNew: (~~(Math.random() * 1e8)).toString(16),
        value: "",
      });
      this.$nextTick(() => {
        this.$refs.valuesBlock.scrollTo({
          top:
            this.$refs.valuesBlock.scrollHeight -
            this.$refs.valuesBlock.clientHeight,
          behavior: "smooth",
        });
      });
    },

    saveTag(method) {
      let values = [];
      if (this.selectedItem.tagType == "value") {
        this.selectedItem.values.forEach((i) => {
          if (i.value) {
            values.push({
              id: i.id ? i.id : "",
              value: i.value,
            });
          }
        });
      }
      this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/tag",
          {
            id: this.selectedItem.id ? this.selectedItem.id : "",
            name: this.selectedItem.name,
            tagType: this.selectedItem.tagType,
            values: values,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.getItems();
          this.$nextTick(() => {
            method();
          });
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
  },
  created() {
    this.getItems();
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  padding: 40px 64px 0px 64px;
  height: 100%;
  width: 100%;
  position: relative;
  .container-title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-block-text {
      color: var(--app-black);
      font-size: 28px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0px;
    }
    .title-block-search {
      width: 300px;
      min-width: 300px;
    }
  }
  .container-subtitle-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 40px 0px 12px 0px;
    position: relative;
    .buttons-block-search {
      min-width: 300px;
    }
    .subtitle-block-count {
      font-size: 16px;
      color: var(--app-grey-3);
    }
  }
  .container-table {
    height: calc(100vh - 180px);
  }
  .dialog-items {
    .dialog-items-subheader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .subheader-text {
        font-size: 22px;
        font-weight: 600;
        color: var(--app-black);
      }
    }
    .dialog-items-values {
      max-height: 400px;
      overflow: auto;
      padding-top: 10px;
      .values-block {
        display: flex;
        justify-content: space-between;
        .values-block-icon {
          margin-left: 24px;
          margin-top: 10px;

          cursor: pointer;
        }
      }
    }
  }
  .dialog-text {
    font-weight: 500;
    color: var(--app-black);
    font-size: 14px;
  }
  .dialog-table-container {
    max-height: 400px;
  }
}
</style>